
.menu-details-container{
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0.12rem;
  .menu-button-row{
    display: flex;
    justify-content: space-between;
    //flex: 1;
    width: 100%;
    margin-bottom: 0.1rem;
    .menu-button{
      flex: 1;
      border: 1px solid #232E40;
      background: #307596;
      height: 0.8rem;
      border-radius: 0.2rem;
    }
  }

}

