
.custom-header{
    display: flex;
    width: 100%;
    background: #072335;
    flex-direction: row;
    padding: 0.1rem;
    box-sizing: border-box;
    height: 1.18rem;
    .right-info{
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding: 0 0.1rem;
    }

    .line1-con,.line2-con{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 0.4rem;
        img{
            width: 0.4rem;
            height: 0.4rem;
            background-blend-mode: darken, normal;
        }
    }

}
