
.character-details-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  .archive-filter{
    width: 7.3rem;
    height: 1rem;
    opacity: 1;
    background: #3B7082;
    display: flex;
    align-items: start;
    --adm-font-size-9:0.28rem;
    padding: 0.36rem 0;
    box-sizing: border-box;
    .filter-title{
      font-size: 0.28rem;
      //line-height: 1rem;
      margin-right: 0.32rem;
      color: #F6C509;
    }
    .adm-radio{
      margin-right: 0.2rem;
    }
    .adm-radio .adm-radio-icon{
      --icon-size:0.28rem;
    }
    .adm-radio-content{
      --gap: 0.1rem;
    }
  }
  .cha-tabs{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.12rem 0.12rem;
    background: #09384D;
    .cha-tab-item{
      width: 1.06rem;
      height: 0.68rem;
      background: #2C4F62;
      font-size: 0.28rem;
      padding: 0;
      margin-right: 0.1rem;
    }

    .cha-tab-item.active{
      background: #DD524D;
    }
  }


  .details-container{
    padding: 0.16rem 0.1rem;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    .property-row{
      display: flex;
      .property-item{
        flex: 1;
        min-width: 3.65rem;
        height: 0.82rem;
        background: #3B7082;
        box-sizing: border-box;
        border-width: 1px 1px 0px 0px;
        border-style: solid;
        border-color: #2C4F62;
        line-height: 0.62rem;
        font-size: 0.28rem;
        padding: 0.1rem;
      }
    }

    .equip-row{
      display: flex;
      justify-content: center;
      justify-content: space-around;
      .unload-equip{
        position: absolute;
        width: 0.6rem;
        height: 0.4rem;
        font-size: 0.24rem;
        padding: 0;
        background: #09384D;
        bottom: 0;
        right: -0.6rem;
      }
    }
    .equip-bg{
      filter: grayscale(100%);
      opacity: 0.2;
      position: absolute;
      top: 0;
    }

    .spit-line{
      //position: absolute;
      width: 100%;
      height: 1px;
      opacity: 1;
      background: #278EA5;
      margin-top: 0.24rem;
    }

    .equip-property-row{
      display: flex;
      justify-content: space-between;
      margin: 0.15rem;
    }

    .equip-property-item{
      font-size: 0.28rem;
      width: 3.5rem;
      text-align: left;
    }
  }
}




.ability-modal.adm-modal-body{
  padding-top: 0;
  width: 5.4rem;
  height: 4.64rem;
  box-sizing: border-box;
  --border-radius:0px;
  background: #23434E;
  .adm-modal-footer-empty{
    height: 0px;
  }
  .adm-modal-content{
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid ;
    box-sizing: border-box;
    padding: 0;
    height: 2rem;
    width: 5.4rem;
    .top-content{
      padding: 0.34rem 0.54rem;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  }
}
