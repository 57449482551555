
.character-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .back-btn {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 1rem;
    padding: 0;
    height: 0.72rem;
    border-radius: 0.12rem;
    background: #09384D;
    font-size: 0.36rem;
  }

  .character-items {
    padding: 0.54rem 1.52rem 0.4rem 1.5rem;
    display: grid;
    grid-template-columns: 1.36rem 1.36rem 1.36rem;
    grid-row-gap: 0.2rem;
    grid-column-gap: 0.2rem;
  }

  .character-info-container {
    padding: 0.4rem;
    text-align: left;
    width: 100%;

    .adm-input{
      height: 0.8rem;
      background: #3B7082;
      border: 0.02rem solid #232E40;
      border-radius: 0.2rem;
      padding: 0 0.2rem;
      box-sizing: border-box;
    }

  }

  .character-container-form {
    --border-bottom: none;
    --border-top: none;
    --border-inner: none;
  }
}
