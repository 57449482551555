
.server-item{
  width: 6.68rem;
  padding: 0;
  height: 0.8rem;
  background: #307596;
  border: 2px solid #232E40;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.2rem;
  position: relative;
  .server-item-tag{
    position: absolute;
    right: 0.3rem;
    width: 0.62rem;
    height: 0.28rem;
    border-radius: 2rem;
    background: #F00F00;
    color: white;
    font-size: 0.2rem;
    line-height: 0.28rem;
    top: 0.26rem;
  }
}

.server-item:nth-of-type(n+2){
  margin-top: 0.2rem;
}
