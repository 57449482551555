
.server-container{
  padding: 0.4rem;
  isplay:flex;
  flexDirection:column;
  alignItems:center;
}

.game-notice{
  width: 0.52rem;
  height: 0.26rem;
  color: #EAD15E;
}
