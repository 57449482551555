
.task-details-container {

  display: flex;
  flex-direction: column;
  height: 100%;

  .task-header {
    background: #09384D;
    height: 0.94rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.12rem 0.2rem;
    font-size: 0.28rem;
  }

  .task-list {
    padding: 0 0.1rem;
    flex: 1;
  }

  .task-progress{
    width: 6.16rem;
    height: 0.4rem;
    background: #333333;
    box-sizing: border-box;
    border: 1px solid #B0B4E0;
    margin-top: 0.3rem;
    position: relative;
    .task-progress-value{
      position: absolute;
      height: 100%;
      max-width: 100%;
      background: #F6C509;
      top: 0;
      left: 0;
    }
    .task-award-1{
      position: absolute;
      left: 1.54rem;
      width: 0.08rem;
      height: 0.36rem;
      background: #FFFF00;
    }
    .task-award-2{
      position: absolute;
      left: 3.08rem;
      width: 0.08rem;
      height: 0.36rem;
      background: #1A80A2;
    }
    .task-award-3{
      position: absolute;
      left: 4.62rem;
      width: 0.08rem;
      height: 0.36rem;
      background: #1A80A2;
    }
    .task-award-4{
      position: absolute;
      right: 0rem;
      width: 0rem;
      height: 0.36rem;
      background: #1A80A2;

    }
    .award-label{
      width: 0.64rem;
      position: absolute;
      font-size: 0.16rem;
      text-align: center;
      bottom: -0.36rem;
      left: -0.24rem;
    }
  }
}
