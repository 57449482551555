
.backpack-details-container{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding: 0.1rem;
  height: 100%;
  overflow: hidden;
  .quality-filter{
    width: 7.3rem;
    height: 1rem;
    opacity: 1;
    background: #3B7082;
    display: flex;
    align-items: start;
    --adm-font-size-9:0.28rem;
    padding: 0.36rem 0;
    box-sizing: border-box;
    .filter-title{
      font-size: 0.28rem;
      //line-height: 1rem;
      margin-right: 0.32rem;
      color: #F6C509;
    }
    .adm-radio{
      margin-right: 0.2rem;
    }
    .adm-radio .adm-radio-icon{
      --icon-size:0.28rem;
    }
    .adm-radio-content{
      --gap: 0.1rem;
    }
  }

  .type-filter{
    margin-top: 0.1rem;
    width: 7.3rem;
    opacity: 1;
    background: #3B7082;
    display: flex;
    text-align: left;
    -adm-font-size-9:0.28rem;
    .filter-title{
      font-size: 0.28rem;
      min-width: 1.12rem;
      margin-right: 0.32rem;
      color: #F6C509;
    }
    .adm-radio{
      margin-right: 0.2rem;
    }
    .adm-radio .adm-radio-icon{
      --icon-size:0.28rem;
    }
    .adm-radio-content{
      --gap: 0.1rem;
    }
  }
  .item-list{
   overflow: scroll;
  }

  .backpack-tabs{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.12rem 0.12rem;
    background: #09384D;
    .backpack-tab-item{
      width: 1.46rem;
      height: 0.68rem;
      background: #2C4F62;
      font-size: 0.28rem;
      padding: 0;
      margin-right: 0.1rem;
    }

    .backpack-tab-item.active{
      background: #DD524D;
    }
  }
}

.craft-container{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding: 0.1rem;
  height: 100%;
  overflow: hidden;
  .result-box{
    width: 100%;
    box-sizing: border-box;
    padding: 0.36rem 1.22rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    //line-height: 4rem;
  }
  .material-box{
    width: 5rem;
    height: 4rem;
    border-radius: 0.12rem;
    opacity: 1;
    box-sizing: border-box;
    border: 0.06rem solid #19969F;
    font-size: 0.4rem;

    .material-item{
      width: 1rem;
      height: 1rem;
      opacity: 1;
      background: #0A212F;
      position: relative;
    }
  }
}


.upgrade-modal.adm-modal-body{
  padding-top: 0;
  width: 5.4rem;
  height: 10.64rem;
  --border-radius:0px;
  background: #23434E;
  .adm-modal-footer-empty{
    height: 0px;
  }
  .adm-modal-content{
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid ;
    box-sizing: border-box;
    padding: 0;
    height: 8rem;
    width: 5.4rem;
    .top-content{
      padding: 0.34rem 0.34rem;
      flex: 1;
      overflow: scroll;
    }
  }
}



.info-modal.adm-modal-body{
  padding-top: 0;
  width: 5.4rem;
  min-height: 5.64rem;
  --border-radius:0px;
  background: #23434E;
  .adm-modal-footer-empty{
    height: 0px;
  }
  .adm-modal-content{
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid ;
    box-sizing: border-box;
    padding: 0;
    min-height: 3rem;
    width: 5.4rem;
    .top-content{
      padding: 0.34rem 0.34rem;
    }
  }
}




.exchange-modal.adm-modal-body{
  padding-top: 0;
  width: 5.4rem;
  height: 5.64rem;
  --border-radius:0px;
  background: #23434E;
  .adm-modal-footer-empty{
    height: 0px;
  }
  .adm-modal-content{
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid ;
    box-sizing: border-box;
    padding: 0;
    height: 3rem;
    width: 5.4rem;
    .top-content{
      padding: 0.2rem 0.2rem;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .material-box{
    width: 5rem;
    height: 4rem;
    border-radius: 0.12rem;
    opacity: 1;
    box-sizing: border-box;
    font-size: 0.4rem;
    .material-item{
      width: 1rem;
      height: 1rem;
      opacity: 1;
      background: #0A212F;
      position: relative;
    }
  }
}
