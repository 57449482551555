
.map-item {
  width: 7.26rem;
  height: 1.12rem;
  margin: 0.1rem 0px 0px;
  display: flex;
  background: rgb(59, 112, 130);
  box-sizing: border-box;
  flex-direction: column;

  .map-item-top {
    display: flex;
    padding: 0.2rem 0.3rem;

    .title {
      font-size: 0.34rem;
      color: rgb(255, 204, 0);
    }

    .description {
      font-size: 0.2rem;
      color: rgb(170, 170, 170);
    }
  }

}

.map-item.active {
  background: #1A80A2;
  height: 2.44rem;
  border: 1px solid #0BBEFF;

  .map-item-top {
    border-bottom: 1px solid #0BBEFF;
  }

  .battle-info {
    height: 0.6rem;
    border-bottom: 1px solid #0BBEFF;
    text-align: left;
    line-height: 0.6rem;
    font-size: 0.24rem;
    padding: 0rem 0.3rem;

  }

  .idle-info {
    height: 0.6rem;
    text-align: left;
    line-height: 0.6rem;
    font-size: 0.24rem;
    padding: 0rem 0.3rem;
    display: flex;
    justify-content: space-between;

  }
}
