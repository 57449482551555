
.login-form.adm-form .adm-list.adm-list {
  --padding-left: 0.2rem !important;
  --prefix-width: 1.5rem;
  text-align: left;
  --border-bottom: None;
  --border-top: None;
  --border-inner: None;

  .adm-list-item {
    border-bottom: None;
  }
  .adm-input{
    background: #3B7082;
    border: 1px solid #232E40;
    border-radius: 0.12rem;
    height: 0.8rem;
    padding: 0.1rem;
    box-sizing: border-box;
  }
}
