
.message-list {
  flex: 1;
  overflow: scroll;
  padding-bottom: 1rem;
  display: flex;
  height: auto;
  flex-direction: column-reverse;
  .adm-list {
    --border-bottom: solid 0px black;
    --border-inner: solid 0px black;
    min-height: 100%;
    .adm-list-body-inner{
      display: flex;
      flex-direction: column-reverse;
    }
    .adm-list-item {
      font-size: 0.28rem;
      line-height: 0.28rem;
      text-align: left;
      --align-items: baseline;

      .adm-list-item-content-main {
        padding: 4px 0;
        color: #f5f5f5;
        font-size: 0.28rem;
        word-break: break-all;
      }

      .adm-list-item-content-prefix {
        color: #F6C509;
        padding-right: 0;
      }
    }
  }

}
