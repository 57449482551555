
.confirm-modal.adm-modal-body {
  padding-top: 0;
  width: 5.4rem;
  min-height: 3rem;
  box-sizing: border-box;
  --border-radius: 0px;
  background: #23434E;

  .adm-modal-footer-empty {
    height: 0px;
  }

  .adm-modal-content {
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid;
    box-sizing: border-box;
    padding: 0;
    height: 2rem;
    width: 5.4rem;

    .top-content {
      padding: 0.34rem 0.54rem;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  }
}