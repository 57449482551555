
.territory-item {
  width: 7.26rem;
  height: 1.62rem;
  margin: 0.1rem 0px 0px;
  display: flex;
  background: rgb(59, 112, 130);
  box-sizing: border-box;
  flex-direction: column;

  .territory-item-top {
    display: flex;
    padding: 0.2rem 0.3rem;
    justify-content: space-between;
    .title {
      font-size: 0.34rem;
      color: rgb(255, 204, 0);
    }

    .description {
      font-size: 0.2rem;
      color: rgb(170, 170, 170);
    }
  }

}

.territory-item.active {
  background: #1A80A2;
  height: 2.14rem;
  border: 1px solid #0BBEFF;

  .territory-item-top {
    border-bottom: 1px solid #0BBEFF;
  }

  .battle-info {
    height: 0.6rem;
    border-bottom: 1px solid #0BBEFF;
    text-align: left;
    line-height: 0.6rem;
    font-size: 0.24rem;
    padding: 0rem 0.3rem;

  }

  .idle-info {
    height: 0.6rem;
    text-align: left;
    line-height: 0.6rem;
    font-size: 0.24rem;
    padding: 0rem 0.3rem;
    display: flex;
    justify-content: space-between;

  }
}

.territory-details-container{
  .adm-list-item{
    padding-left:0.1rem;
  }

  .territory-tabs {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.12rem 0.12rem;
    background: #09384D;

    .territory-tab-item {
      width: 1.46rem;
      height: 0.68rem;
      background: #2C4F62;
      font-size: 0.28rem;
      padding: 0;
      margin-right: 0.1rem;
    }

    .territory-tab-item.active {
      background: #DD524D;
    }
  }
}
