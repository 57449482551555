
.battle-details-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.dungeons-modal {

  .dungeons-level-modal.adm-modal-body {
    padding-top: 0;
    width: 5.4rem;
    height: 5rem;
    --border-radius: 0px;
    background: #23434E;
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid;

    .adm-modal-footer-empty {
      height: 0px;
    }

    .adm-modal-title {
      margin-bottom: 0;
      height: 0.8rem;
      line-height: 0.8rem;
    }

    .adm-modal-content {

      box-sizing: border-box;
      padding: 0;
      height: 100%;
      position: relative;
      width: 5.4rem;

      .level-button {
        width: 4rem;
      }

      .top-content {
        padding: 0.34rem 0.54rem;
      }
    }
  }

  .adm-center-popup-close {
    top: 0.2rem;
    right: 0.2rem;
    font-size: 0.4rem;
    padding: 0;
  }

}


.battle-map-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;


  .battle-logcat-container {
    .battle-logcat {
      text-align: left;
      font-size: 0.24rem;
    }

    .log-round {
      color: gold;
      font-weight: bold;
    }

    .log-title {
      color: blue;
    }

    .log-player {
      color: green;
      text-decoration: underline
    }

    .log-target {
      color: red;
      text-decoration: underline
    }

    .log-damage {
      color: red;
      font-style: italic;
    }

    .log-skill {
      color: orange;
      text-decoration: underline
    }

    .log-buffer {
      color: yellow;
    }
  }
}


.songjin-container {
  .songjin-tabs {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.12rem 0.12rem;
    background: #09384D;

    .songjin-tab-item {
      width: 1.46rem;
      height: 0.68rem;
      background: #2C4F62;
      font-size: 0.28rem;
      padding: 0;
      margin-right: 0.1rem;
    }

    .songjin-tab-item.active {
      background: #DD524D;
    }
  }

  .top-container {
    width: 7.1rem;
    height: 5rem;
    border-radius: 0.2rem;
    opacity: 1;
    background: #3B7082;

    .song-btn {
      opacity: 1;
      background: #19969F;
      box-sizing: border-box;
      border: 0.02rem solid #072335;
      width: 1.62rem;
      height: 0.68rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
    }

    .jin-btn {
      opacity: 1;
      background: #FFAA00;
      box-sizing: border-box;
      border: 0.02rem solid #072335;
      width: 1.62rem;
      height: 0.68rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
    }

    .battle-btn {
      opacity: 1;
      box-sizing: border-box;
      width: 2.2rem;
      height: 0.7rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
      background: linear-gradient(180deg, #FDF68A 0%, #E6BA39 100%);

    }
  }

  .bottom-container {
    width: 7.1rem;
    height: 3.2rem;
    border-radius: 0.2rem;
    margin-top: 0.1rem;
    opacity: 1;
    background: #3B7082;
    box-sizing: border-box;
    padding: 0.16rem 0.28rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    .songjin-tip {
      font-size: 0.3rem;
      font-weight: bold;
      line-height: 0.44rem;
      text-align: center;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      background: linear-gradient(180deg, #F6C509 0%, #F03F03 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      font-size: 0.32rem;
      height: 0.46rem;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #FFFF00;
    }

    .label {
      font-size: 0.34rem;
      height: 0.24rem;
      font-weight: normal;
      line-height: 0.34rem;
      text-align: left;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #ffffff;
    }
  }
}




.menpai-container {
  .menpai-tabs {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 0.12rem 0.12rem;
    background: #09384D;

    .menpai-tab-item {
      width: 1.46rem;
      height: 0.68rem;
      background: #2C4F62;
      font-size: 0.28rem;
      padding: 0;
      margin-right: 0.1rem;
    }

    .menpai-tab-item.active {
      background: #DD524D;
    }
  }

  .top-container {
    width: 7.1rem;
    height: 5rem;
    border-radius: 0.2rem;
    opacity: 1;
    background: #3B7082;

    .song-btn {
      opacity: 1;
      background: #19969F;
      box-sizing: border-box;
      border: 0.02rem solid #072335;
      width: 1.62rem;
      height: 0.68rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
    }

    .jin-btn {
      opacity: 1;
      background: #FFAA00;
      box-sizing: border-box;
      border: 0.02rem solid #072335;
      width: 1.62rem;
      height: 0.68rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
    }

    .battle-btn {
      opacity: 1;
      box-sizing: border-box;
      width: 2rem;
      height: 0.7rem;
      font-size: 0.28rem;
      margin-bottom: 0.1rem;
      background: linear-gradient(180deg, #FDF68A 0%, #E6BA39 100%);

    }
  }

  .bottom-container {
    width: 7.1rem;
    height: 3.2rem;
    border-radius: 0.2rem;
    margin-top: 0.1rem;
    opacity: 1;
    background: #3B7082;
    box-sizing: border-box;
    padding: 0.16rem 0.28rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    .menpai-tip {
      font-size: 0.3rem;
      font-weight: bold;
      line-height: 0.44rem;
      text-align: center;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      background: linear-gradient(180deg, #F6C509 0%, #F03F03 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .title {
      font-size: 0.32rem;
      height: 0.46rem;
      font-weight: normal;
      line-height: normal;
      text-align: left;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #FFFF00;
    }

    .label {
      font-size: 0.34rem;
      height: 0.24rem;
      font-weight: normal;
      line-height: 0.34rem;
      text-align: left;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #ffffff;
    }
  }
}


.battle-songjin-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;


  .songjin-logcat-container {
    .battle-logcat {
      text-align: left;
      font-size: 0.24rem;
    }

    .log-round {
      color: gold;
      font-weight: bold;
    }

    .log-title {
      color: blue;
    }

    .log-player {
      color: green;
      text-decoration: underline
    }

    .log-target {
      color: red;
      text-decoration: underline
    }

    .log-damage {
      color: red;
      font-style: italic;
    }

    .log-skill {
      color: orange;
      text-decoration: underline
    }

    .log-buffer {
      color: yellow;
    }
  }

  .bottom-info {
    width: 7.5rem;
    background: #09384D;
    height: 1.4rem;
    box-sizing: border-box;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: #000000;
    display: flex;
    flex-direction: column;
    padding: 0.26rem 0.32rem;
    justify-content: space-between;
  }
}




.battle-menpai-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;


  .menpai-logcat-container {
    .battle-logcat {
      text-align: left;
      font-size: 0.24rem;
    }

    .log-round {
      color: gold;
      font-weight: bold;
    }

    .log-title {
      color: blue;
    }

    .log-player {
      color: green;
      text-decoration: underline
    }

    .log-target {
      color: red;
      text-decoration: underline
    }

    .log-damage {
      color: red;
      font-style: italic;
    }

    .log-skill {
      color: orange;
      text-decoration: underline
    }

    .log-buffer {
      color: yellow;
    }
  }

  .bottom-info {
    width: 7.5rem;
    background: #09384D;
    height: 1.4rem;
    box-sizing: border-box;
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: #000000;
    display: flex;
    flex-direction: column;
    padding: 0.26rem 0.32rem;
    justify-content: space-between;
  }
}

.rank-modal.adm-modal-body{
  padding-top: 0;
  width: 5.4rem;
  height: 7.8rem;
  --border-radius:0px;
  background: #23434E;
  .adm-modal-footer-empty{
    height: 0px;
  }
  .adm-modal-content{
    border-image: linear-gradient(180deg, #FFED10 0%, #726E34 100%) 3;
    border: 3px solid ;
    box-sizing: border-box;
    padding: 0;
    height: 3rem;
    width: 5.4rem;
    .top-content{
      padding: 0.2rem 0.2rem;
      .rank-item{
        width: 5rem;
        height: 0.9rem;
        display: flex;
        margin-bottom: 0.1rem;
      }

      .rank{
        border-radius: 0.12rem 0px 0px 0.12rem;
        background: #1589A5;
        width: 1.06rem;
        line-height: 0.9rem;
        text-align: center;
        font-size: 0.32rem;
      }
    }
  }
}
